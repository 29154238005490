
<template #content>
  <div class="main-container">
    <div class="container-email">
      <h1 class="Headline headline2 is-size-4" v-t="'changeEmail.title'" />

      <div class="info" v-if="!showError && !showSuccess"> {{$t('changeEmail.infoMessage')}} </div>
      <div v-if="showSuccess" class="alert alert-success password-reset-message">{{$tm('changeEmail.successMessage')}}</div>

        <Form @submit="submit" v-slot="errors" class="input-fields">
          <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email|disallowEmail:${user.email}`" type="email" :errors="errors"/>
          <input-component :fieldName="fieldNames.emailConf" :fieldLabel="emailConfirmationFieldName" :rules="`required|confirm:@${fieldNames.email}`" type="email" :errors="errors"/>
          <div v-if="showError" class="alert alert-danger password-reset-message">{{ $t('changeEmail.errorMessage') }}</div>
            <div class="button-fields">
              <div class="content sub-headline">
                <div class="row">
                  <button
                    v-if="!showSuccess"
                    class="Button"
                    :disabled="isLoading"
                    v-text="$tm('changeEmail.sendChangeEmailButtonText')"
                  />
                </div>

                <div class="row">
                  <button
                    class="Button"
                    @click="close"
                    v-text="this.closeButtonText"
                  />
                </div>
              </div>
            </div>
        </Form>
    </div>
    <the-portal-footer class="footer"/>
  </div>
</template>

  <script>
  import { mapActions, mapGetters } from 'vuex'
  import ThePortalFooter from '@/components/ThePortalFooter.vue'
  import { Form } from 'vee-validate'
  import InputComponent from '@/components/partials/InputComponent.vue'

  export default {
    name: 'ChangeEmailPage',

    components: {
      ThePortalFooter,
      Form,
      InputComponent
    },

    data () {
      return {
        showSuccess: false,
        showError: false,
        isLoading: false,
        fieldNames: {
          email: 'email',
          emailConf: 'emailConf'
        }
      }
    },

    computed: {
      ...mapGetters('user', ['user']),
      emailFieldLabel () {
        return this.$t('form.email')
      },
      emailConfirmationFieldName () {
        return this.$t('form.emailConfirmation')
      },
      closeButtonText () {
        return this.showSuccess ? this.$t('changeEmail.closeButtonAndLogoutText') : this.$t('changeEmail.closeButtonText')
      }
    },

    methods: {
      ...mapActions({ initiateEmailChange: 'user/initiateEmailChange' }),

      close (e) {
        if (this.showSuccess) {
          this.$auth0.logout()
        } else {
          window.location.href = window.location.origin
        }
      },

      async submit (values) {
        this.isLoading = true
        try {
          await this.initiateEmailChange(values[this.fieldNames.email])
          this.renderResultMessage(true)
        } catch (error) {
          this.renderResultMessage(false)
        }
        this.isLoading = false
      },

      renderResultMessage (isSuccess) {
        this.showSuccess = isSuccess
        this.showError = !isSuccess
      }
    }
  }
  </script>

<style lang="scss" scoped>
.input-fields {
  width: 80%;
  text-align: center;
  margin: auto;
}

  .row {
    margin: auto;
    margin-top: 10px;
    padding-top: 3%;
  }

  .container-email{
    max-width: 750px;
    display: block;
    margin: auto;
    margin-top: 5%;
    padding-bottom: 5%;
  }

  .headline2{
    text-align: center;
    margin-bottom: 2%;
  }

  .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .password-reset-message {
    margin-top: 1em;
  }

  .info{
    margin-bottom: 30px;
    text-align: center;
  }

  .footer {
    width: 100%
  }

  .button-fields{
    max-width: 100%;
    font-size: medium;
    text-align: center;
  }
  .main-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  </style>
